import { BbdJson, CombinedBannerList, IBanner, bannerDataType } from '@/types/home'
import { isServer } from '..'
import { IPageData } from '@/types'
import { COMMON_SEO_TITLE } from '@/constants/components/common'

export const normalizeSize = (size: number | string) => {
    if (typeof size === 'number') return `${size}px`
    return size
}

export const isFeatureEnabled = (offerSlots: BbdJson[]) => {
    if (!isServer()) {
        const data = offerSlots || []
        const currentTime = new Date()

        const currentOffset = currentTime.getTimezoneOffset()

        const ISTOffset = 330 // IST offset UTC +5:30

        const ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000)

        for (let index = 0; index < data.length; index++) {
            const config = data[index]
            if (new Date(config.time.from) <= ISTTime && new Date(config.time.to) >= ISTTime) {
                return { ...config }
            }
        }
    }
}
export const isFeatureEnabledOfferSlots = () => {
    const data = !isServer() ? window?.offerJson || [] : []
    let currentTime = new Date()

    let currentOffset = currentTime.getTimezoneOffset()

    let ISTOffset = 330 // IST offset UTC +5:30

    let ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000)
    let obj = { humbgerSale: { flights: { enabled: false, text: '', subHeading: '' } } }
    for (let index = 0; index < data.length; index++) {
        let config = data[index]
        if (new Date(config.time.from) <= ISTTime && new Date(config.time.to) >= ISTTime) {
            obj = { ...config }
            break
        }
    }

    return obj
}
export function getSEOTitle(response: IPageData) {
    const { pageMisc: { SEOTitle = COMMON_SEO_TITLE } = {} } = response ?? {}

    return SEOTitle as string
}

export const getBannerData = (data: CombinedBannerList) => {
    let bannerData: bannerDataType = []
    data?.forEach(banner => {
        if (!('inCarousel' in banner[0])) {
            bannerData = [
                ...bannerData,
                {
                    enabled: true,
                    banners: banner as IBanner[]
                }
            ]
        }
    })
    return bannerData
}

export const getCarousalData = (bbdData: CombinedBannerList) => {
    let carouselBanners: IBanner[] = []
    const data = bbdData
    data?.forEach(ele => {
        ele.forEach(banner => {
            if ('inCarousel' in banner && banner.inCarousel && carouselBanners.length === 0) {
                return (carouselBanners = banner.banner)
            }
        })
    })
    return carouselBanners
}


export const isV7 = (itineraryData: {
    SEARCH_DETAILS?: {
        itineraryTags?: string[]
    }
}): boolean => {
    return itineraryData?.SEARCH_DETAILS?.itineraryTags?.includes('CFW') || itineraryData?.SEARCH_DETAILS?.itineraryTags?.includes('DOM_FF') || false
}
